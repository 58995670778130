import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, debounce } from 'rxjs/operators';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { DataService } from './data.service';
import { environment } from 'src/environments/environment';
import { Http, Response, ResponseContentType } from '@angular/http';
import { filterParameter } from 'src/Models/filterParameter';

@Injectable({ providedIn: 'root' })

export class UniversalService {

    constructor(private _data: DataService) { }

    GetToken(): Observable<any> {
        return this._data.post('UniversalService/GetToken')
            .pipe(map(response => {
                return response;
            }));
    }

    CheckEligibilityStatus(EligibilityId: any, isStatusSaveOnDB: any): Observable<any> {
        let isRecordLock = false;
        let URL = 'UniversalService/CheckEligibilityStatus/' + EligibilityId + '/' + isStatusSaveOnDB + '/' + isRecordLock;
        return this._data.post(URL, { 'eligibilityId': EligibilityId, 'isStatusSaveOnDB': isStatusSaveOnDB, 'isRecordLock':isRecordLock })
            .pipe(map(response => {
                return response;
            }));
    }

    FurtherApply(UserId: any, LoginUserId: any): Observable<any> {
        return this._data.post('UniversalService/FurtherApply/' + UserId + '/' + LoginUserId, { 'UserId': UserId, 'LoginUserId': LoginUserId })
            .pipe(map(response => {
                return response;
            }));
    }

    AdminEligibilityCheck(model: filterParameter): Observable<any> {
        return this._data.post('UniversalService/AdminEligibilityCheck', model)
            .pipe(map(response => {
                return response;
            }));
    }

    ReSubmittedAndApply(applicationHistoryId: any, LoginUserId: any): Observable<any> {
        return this._data.post('UniversalService/ReSubmittedAndApply/' + applicationHistoryId + '/' + LoginUserId, { 'applicationHistoryId': applicationHistoryId, 'LoginUserId': LoginUserId })
            .pipe(map(response => {
                return response;
            }));
    }

    CheckDeviceStatus(applicationHistoryId: number,createdby:number): Observable<any> {
        return this._data.get('UniversalService/VerifyDeviceEligibility?applicationHistoryId=' + applicationHistoryId+'&createdby='+createdby)
          .pipe(map(response => {
            return response;
          }));
      }
}
