import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { getIntParam, movetotop } from 'src/app/Shared/commonMethods';
import { AuthService } from 'src/app/Shared/auth.service';
import { StateService } from '../../../Services/state.service';
import { ProviderService } from '../../../Services/provider.service';

@Component({
  selector: 'app-add-edit-provider',
  templateUrl: './add-edit-provider.component.html',
  styleUrls: ['./add-edit-provider.component.css']
})
export class AddEditProviderComponent implements OnInit {
  Id: number = 0;
  pageTitle = 'Add Provider';
  providerForm: FormGroup;
  statelist = [];
  providerStatelist = [];
  isloading = false;
  isloadingstates = false;
  submitted = false;
  selectedAllState = false;

  constructor(
    private _fb: FormBuilder,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private auth: AuthService,
    private stateService: StateService,
    private providerService: ProviderService
  ) {
    this.providerForm = this._fb.group({
      id: [0],
      name: ['', [Validators.required]],
      address: [''],
      stateId: ['', [Validators.required]],
      city: [''],
      zipcode: [''],
      emailId: ['', [Validators.email]],
      webUrl: [''],
      isActive: [1],
    });
  }

  ngOnInit() {
    this.getState();
    this.titleService.setTitle('Add Provider');
    this.Id = getIntParam(this.activatedRoute.params, 'id');
    if (this.Id > 0) {
      this.titleService.setTitle('Edit Provider');
      this.pageTitle = 'Edit Provider';
      this.GetProvider();
    }
    this.getProviderStateList();
  }

  get f() { return this.providerForm.controls; }

  GetProvider() {
    this.isloading = true;
    this.providerService.GetProvider(this.Id).subscribe(data => {      
      this.isloading = false;
      // this.providerForm.setValue(data);      
      this.providerForm.setValue({
        id: data['id'],
        name: data['name'],
        address: data['address'],
        stateId: data['stateId'],
        city: data['city'],
        zipcode: data['zipcode'],
        emailId: data['emailId'],
        webUrl: data['webUrl'],
        isActive: data['isActive'],
      });
    }, error => {
      this.isloading = false;
    });
  }

  saveProvider() {
    this.submitted = true;
    if (this.auth.checkValidation(this.providerForm)) {
      let providerStateModel = this.providerStatelist.filter(x => x.isSelected === true);

      let providerModel = this.providerForm.value;
      providerModel.providerStateModel = providerStateModel;
      this.providerService.SaveProvider(providerModel).subscribe(data => {
        this.toastr.success("Provider has been updated successfully", "Success")
        this.router.navigate(['/admin/providers']);
      }, error => {
        this.submitted = false;
      });

    }
  }

  getState() {
    this.isloading = true;
    this.stateService.GetStateList().subscribe(data => {
      if (data) {
        this.isloading = false;
        this.statelist = data;
      }
    }, error => {
      this.isloading = false;
    });
  }

  getProviderStateList() {
    this.isloading = true;
    this.stateService.GetProviderStateList(this.Id).subscribe(data => {
      if (data) {
        this.isloading = false;
        this.providerStatelist = data;
      }
    }, error => {
      this.isloading = false;
    });
  }

  selectAll() {
    for (var i = 0; i < this.providerStatelist.length; i++) {
      this.providerStatelist[i].isSelected = this.selectedAllState;
    }
  }

  checkIfAllSelected() {
    this.selectedAllState = this.providerStatelist.every(function (item: any) {
      return item.ischecked == true;
    })
  }

  cancel() {
    this.router.navigate(['/admin/providers']);
  }
}
