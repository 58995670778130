import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UserModel } from '../../../Models/usersModel';
import { UsersService } from '../../../Services/users.service';
import { Title } from '@angular/platform-browser';
import { PaginationModel } from 'src/Models/paginationModel';
import { movetotop, getIntParam, checkContainesURL, getEncryptedString } from 'src/app/Shared/commonMethods';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { Globle } from 'src/app/Shared/global';
import { UserType } from 'src/app/Shared/AppEnums';
import * as FileSaver from 'file-saver';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  providerId = 0;
  listOfUser: UserModel[];
  pagination = new PaginationModel();
  isAscending: boolean;
  isloading = false;
  upDowFirstNameCSS = '';
  upDowEmailCSS = '';
  upDowIsActiveCSS = '';
  upDowGroupNameCSS = '';
  upDowLocationCSS = '';
  upDownlModifiedByCSS = '';
  searchTextChanged = new Subject<string>();
  userTypeString = '';
  userType = 0;
  userCount = -1;
  fromProvider = false;
  locationId = '';
  screenTitle = "Users";
  totalRecordsCount: number = -1;
  userID: number = 0;
  listOfExports: any = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userService: UsersService,
    private titleService: Title,
    private globle: Globle,
    private toastr: ToastrService,
  ) {
    
    this.userID = this.globle.cUser.id;
    this.listOfUser = [];
    this.pagination.orderBy = 'firstName  ASC';
    this.pagination.pageSize = 10;
    this.pagination.pageNumber = 1;
    this.pagination.userId = this.userID.toString();
    this.userTypeString = UserType[this.globle.cUser.userTypeId];
  }

  ngOnInit() {
    movetotop();

    this.titleService.setTitle('Users');
    this.fromProvider = checkContainesURL(this.router, 'providers');
    this.providerId = getIntParam(this.activatedRoute.params, 'id');
    this.userType = getIntParam(this.activatedRoute.params, 'usertype');


    if (this.globle.cUser.userTypeId == 1 && this.fromProvider) //Admin User
    {
      this.screenTitle = "Provider's Users";
    }

    if (this.globle.cUser.userGroupId == 4 || this.globle.cUser.userGroupId == 5) {
      this.locationId = this.globle.cUser.locationId
    }
    else {
      this.locationId = '0';
    }


    this.GetProviderUsers();
    this.searchTextChanged.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      mergeMap(search => this.userService.GetProviderUsers(this.userType, this.providerId, this.locationId, this.globle.cUser.userGroupId,false, this.pagination))
    ).subscribe((data) => {
      
      this.isloading = false;
      this.listOfUser = data;
      this.userCount = this.listOfUser.length;
      if (this.listOfUser != null) {
        if (this.listOfUser.length > 0) {
          this.totalRecordsCount = +this.listOfUser[0].totalRecordCount;
        }
        else
          this.totalRecordsCount = 0;
      }
      else
        this.totalRecordsCount = 0;
    }, error => {
      this.isloading = false;
    });
  }

  GetProviderUsers() {
    this.isloading = true;
    if (!this.fromProvider) {
      this.providerId = this.globle.cUser.providerId;
      this.userType = this.globle.cUser.userTypeId
    }
    this.userService.GetProviderUsers(this.userType, this.providerId, this.locationId, this.globle.cUser.userGroupId,false, this.pagination).subscribe(data => {
      
      this.isloading = false;
      this.listOfUser = data;
      this.userCount = this.listOfUser.length;
      if (this.listOfUser != null) {
        if (this.listOfUser.length > 0) {
          this.totalRecordsCount = +this.listOfUser[0].totalRecordCount;
        }
        else
          this.totalRecordsCount = 0;
      }
      else
        this.totalRecordsCount = 0;
    }, error => {
      this.isloading = false;
    });
  }

  sort(sortBy: any) {
    this.cleanCssClass();
    this.isAscending = !this.isAscending;
    this.pagination.orderBy = this.isAscending ? sortBy : sortBy + ' DESC';

    switch (sortBy) {
      case 'firstName': this.upDowFirstNameCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'emailId': this.upDowEmailCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'isActive': this.upDowIsActiveCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'groupName': this.upDowGroupNameCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'locationId': this.upDowLocationCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'modifiedByName': this.upDownlModifiedByCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      default: break;
    }
    this.GetProviderUsers();
  }

  cleanCssClass() {
    this.upDowEmailCSS = '';
    this.upDowFirstNameCSS = '';
    this.upDowIsActiveCSS = '';
    this.upDowGroupNameCSS = '';
    this.upDowLocationCSS = '';
    this.upDownlModifiedByCSS = '';
  }


  keywordFilters($event) {
    this.isloading = true;
    this.pagination.pageSize = 10;
    this.pagination.pageNumber = 1;
    this.pagination.keywordFilter = $event.target.value;
    this.searchTextChanged.next($event.target.value);
  }

  addUser() {

    if (this.fromProvider) {
      this.router.navigate(['/admin/providers/users/add/' + this.providerId + '/' + this.userType]);
    } else {
      this.providerId = this.globle.cUser.providerId;
      this.router.navigate(['/admin/users/add/' + this.providerId + '/' + this.userType]);
    }
  }
  editUser(id: any) {
    if (this.fromProvider) {
      this.router.navigate(['/admin/providers/users/edit/' + id + '/' + this.providerId + '/' + this.userType]);
    } else {
      this.providerId = this.globle.cUser.providerId;
      this.router.navigate(['/admin/users/edit/' + id + '/' + this.providerId + '/' + this.userType]);
    }
  }

  pageChanged($event: any) {
    this.pagination.pageNumber = $event.page;
    this.GetProviderUsers();
  }

  loginProvider(id: any) {
    var dateStamp = new Date().setMinutes(new Date().getMinutes() + 3);
    var eResult = getEncryptedString(`${id}_${dateStamp}`, "secret key 123");
    window.open(`#/admin/login/${eResult}`, '_blank');
  }
  
  ExportDetails() {
    this.listOfExports = [];
    if (!this.fromProvider) {
      this.providerId = this.globle.cUser.providerId;
      this.userType = this.globle.cUser.userTypeId
    }
    this.isloading = true;
    this.userService.GetProviderUsers(this.userType, this.providerId, this.locationId, this.globle.cUser.userGroupId,true, this.pagination).subscribe(data => {
      this.listOfExports = data;
      setTimeout(this.exportToExcel, 3000);
      this.toastr.success('Please wait while downloading.');
      this.isloading = false;
    },
      error => {
        this.toastr.error('Download failed.');
        this.isloading = false;
     });
  }
  
  exportToExcel() {
    let tableData = document.getElementById("ExportTable").innerHTML;
    var uri = 'data:application/vnd.ms-excel;base64,'
      , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body>{table}</body></html>'
      , base64 = function (s) { return window.btoa(unescape(encodeURIComponent(s))) }
      , format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) }
    let html = "<table><tr class='mainhead'> <td colspan='3'>";
    html += tableData;
    html += "</td></tr></table>";
    let name = 'UserReport';
    var ctx = { worksheet: name || 'Worksheet', table: html }
    
    FileSaver.saveAs((uri + base64(format(template, ctx))), "UserReport.xls");
  }
}


