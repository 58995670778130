import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, debounce } from 'rxjs/operators';
import { throwError as observableThrowError, Observable } from 'rxjs';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { DataService } from './data.service';
import { environment } from 'src/environments/environment';
import { Http, Response, ResponseContentType } from '@angular/http';

import {
    SubscriberPersonalDetailsModel,
    SubscriberAddressModel,
    SubscriberDependentModel,
    SubscriberQualifyModel,

    SubscriberAgreementModel
} from '../Models/subscribeModel';
import { Globle } from 'src/app/Shared/global';

@Injectable({
    providedIn: 'root'
})
export class SubscriberService_ {
    constructor(private dataService: DataService, private global: Globle) { }


    checkSubscriberUserLogin(loginuser, ipAddress): Observable<any> {
        return this.dataService.post(`SubscriberApp/CheckSubscriberUserLogin/` + ipAddress, loginuser)
            .pipe(map(response => {
                if (response && response.users) {
                    return { users: response.users };
                }
                else {
                    if (response && response.user.authToken) {
                        debugger;
                        sessionStorage.setItem('cUser', JSON.stringify(response.user));
                        sessionStorage.setItem('cAppHistory', JSON.stringify(response.appHistory));
                        this.global.cUser = response.user;
                        this.global.cApp.subscriberApplicationHistory = response.appHistory;
                        sessionStorage.setItem('isNewExistingUser', response.user.isNewExistingUser);
                        if (response.user.isOnlineRegister)
                            sessionStorage.setItem('isOnlineRegister', 'true');
                        sessionStorage.setItem('onlineRegistrationID', response.user.onlineRegistrationID);
                        sessionStorage.setItem('onlinePhone', response.user.phone);
                    }
                    return { user: response.user, appHistory: response.appHistory };
                }
            }));

    }

    CheckSubscriberForNewUserRegistration(loginuser, ipAddress): Observable<any> {
        return this.dataService.post(`SubscriberApp/CheckSubscriberForNewUserRegistration/` + ipAddress, loginuser)
            .pipe(map(response => {

                if (response && response.users) {
                    return { users: response.users };
                }
                else {
                    if (response && response.user.authToken) {
                        sessionStorage.setItem('cUser', JSON.stringify(response.user));
                        sessionStorage.setItem('cAppHistory', JSON.stringify(response.appHistory));
                        this.global.cUser = response.user;
                        this.global.cApp.subscriberApplicationHistory = response.appHistory;
                    }
                    return { user: response.user, appHistory: response.appHistory };
                }
            }));
    }

    applicationSubmit(application): Observable<any> {
        return this.dataService.post(`SubscriberApp/ApplicationSubmit`, application)
            .pipe(catchError(this.handleError)
            );
    }

    manuallyApplicationSubmit(application): Observable<any> {
        return this.dataService.post(`SubscriberApp/ManuallyApplicationSubmit`, application)
            .pipe(catchError(this.handleError)
            );
    }

    checkDuplicateUserByEmailId(application): Observable<any> {
        return this.dataService.post(`SubscriberApp/CheckDuplicateUserByEmailId`, application)
            .pipe(catchError(this.handleError)
            );
    }

    reSubmitApplicationFindByApplicationHistoryId(applicationHistoryId): Observable<any> {
        return this.dataService.post(`SubscriberApp/ReSubmitApplicationFindByApplicationHistoryId/` + applicationHistoryId, applicationHistoryId)
            .pipe(catchError(this.handleError)
            );
    }

    ApplyOnlineApplicationSubmit(application): Observable<any> {
        return this.dataService.post(`SubscriberApp/ApplyOnlineApplicationSubmit`, application)
            .pipe(catchError(this.handleError)
            );
    }

    CheckDuplicateUserFindByEmailId(model): Observable<any> {
        return this.dataService.post(`SubscriberApp/CheckDuplicateUserFindByEmailId`, model)
            .pipe(catchError(this.handleError)
            );
    }

    adminEnrollement(application): Observable<any> {
        return this.dataService.post(`SubscriberApp/AdminEnrollement`, application)
            .pipe(catchError(this.handleError)
            );
    }

    GetEligibilityApplicationFindByApplicationHistoryId(applicationHistoryId): Observable<any> {
        return this.dataService.post(`SubscriberApp/GetEligibilityApplicationFindByApplicationHistoryId/` + applicationHistoryId, applicationHistoryId)
            .pipe(catchError(this.handleError)
            );
    }

    ConsentSubmit(consentHistoryId): Observable<any> {
        return this.dataService.post(`SubscriberApp/Consentsubmitwithtimezone`, consentHistoryId)
            .pipe(catchError(this.handleError)
            );
    }

    private handleError(error: Response | any) {
        console.error(error.message || error);
        return observableThrowError(error.status)
    }

    TransferInApplication(application): Observable<any> {
        return this.dataService.post(`SubscriberApp/TransferInApplication`, application)
            .pipe(catchError(this.handleError));
    }
    
    SSNandTINCheck(type,value): Observable<any> {
        return this.dataService.get(`SubscriberApp/SSNandTINCheck/${type}/${value}`)
        .pipe(map(response => {
          return response;
        }));
    }

    CheckSubscriberForTransferInUser(loginuser, ipAddress): Observable<any> {
        return this.dataService.post(`SubscriberApp/CheckSubscriberForTransferInUser/` + ipAddress, loginuser)
            .pipe(map(response => {
                if (response && response.users) {
                    return { users: response.users };
                }
                else {
                    if (response && response.user.authToken) {
                        sessionStorage.setItem('cUser', JSON.stringify(response.user));
                        sessionStorage.setItem('cAppHistory', JSON.stringify(response.appHistory));
                        this.global.cUser = response.user;
                        this.global.cApp.subscriberApplicationHistory = response.appHistory;
                    }
                    return { user: response.user, appHistory: response.appHistory };
                }
            }));
    }
    
    transferINApplication(model: any): Observable<any> {
        return this.dataService.post(`UniversalService/SubscriberTransfer/`, model)
            .pipe(map(response => {
                return response;
            }));
    }  
    
    ApplyOnlineTransferApplication(application): Observable<any> {
        return this.dataService.post(`SubscriberApp/ApplyOnlineTransferApplication`, application)
            .pipe(catchError(this.handleError)
            );
    }
}
