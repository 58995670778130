export class ApplicationStatusModel {
    id?: any;
    firstName?: any;
    lastName?: any;
    middleName?: any;
    emailId?: any;
    customerId?: any = '';
    locationCode?: any = '';
    streetAddress?: any;
    stateId?: any;
    city?: any;
    isActive?: any;
    apiApplicationId?: any;
    APIStatus?: any;
    enrollStatus?: any;
    enrollmentDate?: any;
    isCancelled?: any;
    cancelledNotes?: any;
    cancelledDate?: any;
    isDeEnroll?: any;
    deEnrollDate?: any;
    classDeEnroll?: any;
    postDeEnroll?: any;
    deEnrollStatus?: any;
    stateName: any;
    zipcode: any;
    streetNumberTemp: any;
    stateIdTemp: any;
    cityTemp: any;
    zipcodeTemp: any;
    addressType: any;
    stateShortName: any;
    stateShortNameTemp: any;
    phoneNumber: any;
    modifiedById: any;
    applicationHistoryId: any;
    cancelledCreateDate: any;
    locationId?: any;
    zoneId?: any;
    zoneName?: any;
    locationName?: any;
    isTribalLands?: any;
    ebbpTribalBenefitFlag?: any;
    deviceReimbursementDate?: any;
    deviceType?: any;
    deviceMake?: any;
    expectedRateDevice?: any;
    isNewExistingUser?: any;
    dateOfBirth?: any;
    ssn?: any;
    tribalId?: any;
    isAlreadyEnrolledRadio?: any;

    modelNumber?: any;
    deviceCopay?: any;
    deviceDeliveryMethod?: any;
    deviceModel?: any;
    marketValue?: any;
    isConsumerFee: any;
    consentDate: any;
    isTransferOut: any;
    transferOutNotes: any;
    transferOutDate: any;
    serviceInitializationDate?:any;
    isSubmitPi:boolean=false;
}