import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/Shared/auth.service';
import { UsersService } from '../../../Services/users.service';
import { movetotop, getDecryptedString } from 'src/app/Shared/commonMethods';
import { UserRightsService } from 'src/Services/userrights.service';
import { Globle } from 'src/app/Shared/global';
@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.css']
})
export class AdminLoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  isloading = false;
  currentEnvironment: string = '';

  constructor(
    private toastr: ToastrService,
    private _fb: FormBuilder,
    private _userService: UsersService,
    private _authService: AuthService,
    private router: Router,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private global: Globle
  ) {
    this._userService.logout();

    this.activatedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        var token = getDecryptedString(params['id'], "secret key 123");
        if (token) {
          var data = token.toString().split('_');
          if (new Date(Number(data[1])) > new Date()) {
            this.userManualLogin(Number(data[0]));
          }
          else {
            this.toastr.error("URL expired, can't login.", "Error");
          }
        }
      }
    });
    this.getCurrentEnviroment();
  }

  ngOnInit() {
    movetotop();
    this.titleService.setTitle('Inroll ACP - Admin Login');
    this.loginForm = this._fb.group({
      userid: 0,
      emailId: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }

  get formControl() { return this.loginForm.controls; }

  CheckLogin() {
    this.submitted = true;
    if (this._authService.checkValidation(this.loginForm)) {
      this._userService.checkAdminUserLogin(this.loginForm.value).subscribe(
        data => {
          if (data) {
            this.router.navigate(['admin/dashboard']);
          } else {
            this.toastr.error('Error', 'error');
          }
        }
      );
    }
  }

  userManualLogin(userId: number) {
    this.isloading = true;
    this._userService.checkAdminUserLoginByUserId(userId).subscribe(
      data => {
        if (data) {
          this.isloading = false;
          this.router.navigate(['admin/dashboard']);
        }
      }
    );
  }

  getCurrentEnviroment() {
    this._userService.getCurrentEnviroment().subscribe(
      data => {
        if (data) {          
          if (data.currentenvironment != 'Live') {
            this.currentEnvironment = data.currentenvironment + ' Website' ;
          }
        }
      }
    );
  }

}
